import {
  setToken as httpSetToken,
  unsetToken as httpUnsetToken,
  http
} from '@/support/http'
import axios from 'axios'
import * as TYPES from './mutations-types'
import * as services from '../services'
import * as servicesInfracao from '../services/AutoInfracaoService'
import jwtDecode from 'jwt-decode'
// import { logout as logoutWso2 } from '@/domains/auth/services/Wso2Service'

const tokenIsValid = (token) => {
  const { exp } = jwtDecode(token)
  return (new Date()).getTime() / 1000 <= exp
}

export const attemptLogin = ({ dispatch }, payload) => {
  return services.postLogin(payload).then(({ data }) => {
    return dispatch('registraDados', data)
  })
}

export const registraDados = ({ dispatch, commit }, data) => {
  if (data.token) {
    dispatch('setToken', { token: data.token })
    httpSetToken(data.token)
    dispatch('atualizarRoles', { token: data.token })
  }
  if (data.me) {
    dispatch('setUser', { user: data.me })
  }
  if (data.nomesPid) {
    dispatch('setNomesPid', { nomesPid: data.nomesPid })
  }
  if (data.enderecosAgendamento) {
    commit(TYPES.SET_ENDERECOS_AGENDAMENTO, data.enderecosAgendamento)
  }
  if (data.usuarioNovo) {
    commit(TYPES.SET_USUARIO_NOVO, true)
  }
  if (data.pesquisaSatisfacao) {
    commit(TYPES.SET_PESQUISA_SATISFACAO, data.pesquisaSatisfacao)
  }
  return data
}

export const cadastrarEmpresa = ({ dispatch }, payload) => {
  return services.cadastrarEmpresa(payload).then(({ data }) => {
    return data
  })
}

export const validarEmail = ({ dispatch }, payload) => {
  return services.validarEmail(payload).then(({ data }) => {
    return data
  })
}

export const validarEmailProcesso = ({ dispatch }, payload) => {
  return services.validarEmailProcesso(payload).then(({ data }) => {
    return data
  })
}

export const reenviarEmail = ({ dispatch }, payload) => {
  return services.reenviarEmail(payload).then(({ data }) => {
    return data
  })
}

export const recuperarSenha = ({ dispatch }, payload) => {
  return services.recuperarSenha(payload).then(({ data }) => {
    return data
  })
}

export const trocarSenha = ({ dispatch }, payload) => {
  return services.trocarSenha(payload).then(({ data }) => {
    dispatch('attemptSetToken', { token: data })
    return data
  })
}

export const listarCombinacoesPid = ({ dispatch }) => {
  return services.listarCombinacoesPid().then(({ data }) => {
    return data
  })
}

export const getMe = ({ dispatch }) => {
  return services.getMe().then(({ data }) => {
    dispatch('setUser', { user: data })
    return data
  })
}

export const attemptSetToken = ({ dispatch }, data) => {
  dispatch('setToken', { token: data.token })
  httpSetToken(data.token)
  dispatch('atualizarRoles', { token: data.token })
  return Promise.resolve(data)
}

export const logout = ({ commit, getters }) => {
  httpUnsetToken()
  localStorage.removeItem('app-id-token')
  return commit('resetState')
}

export const setIdToken = ({ commit }, values) => {
  commit(TYPES.ID_TOKEN, values)
}

export const setToken = ({ commit }, values) => {
  commit(TYPES.SET_TOKEN, values)
}

export const setUser = ({ commit }, values) => {
  commit(TYPES.SET_LOGGED_USER, values)
}

export const setNomesPid = ({ commit }, values) => {
  commit(TYPES.SET_NOMES_PID, values)
}

export const atualizarRoles = ({ commit }, values) => {
  const { roles } = jwtDecode(values.token)
  if (roles) {
    commit(TYPES.SET_ROLES, {
      roles: roles.split(',').map((i) => i.replace('ROLE_', ''))
    })
  }
}

export const atualizarRolesDaLista = ({ commit }, roles) => {
  commit(TYPES.SET_ROLES, {
    roles: roles.map((i) => i.replace('ROLE_', ''))
  })
}

export const adicionaRoleVeiculo = ({ commit, state }) => {
  state.roles.push('VEICULO')
  commit(TYPES.SET_ROLES, {
    roles: state.roles
  })
}

export const checkUserToken = ({ dispatch, state }) => {
  if (state.token && tokenIsValid(state.token)) {
    return Promise.resolve(state)
  }

  dispatch('logout')
  return Promise.reject(new Error('Unauthorized.'))
}

export const baixarPdf = ({ state }, url) => {
  const client = url.startsWith('http')
    ? axios
    : http
  return client
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
        Authorization: `Bearer ${state.token}`
      }
    })
    .then((response) => {
      return response
    })
}

export const salvarPush = ({ dispatch }, payload) => {
  return services.salvarPush(payload).then(({ data }) => {
    return data
  })
}

export const cancelarPush = ({ dispatch }) => {
  return services.cancelarPush().then(({ data }) => {
    return data
  })
}

export const pontuacao = ({ dispatch }) => {
  return servicesInfracao.pontuacao().then(({ data }) => {
    return data
  })
}
