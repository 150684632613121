import axios from 'axios'
import interceptors from './interceptors'
import { API_URL } from '@/config'

export const http = axios.create({
  baseURL: API_URL,
  headers: {
    Pragma: 'no-cache'
  }
})

export function setToken (token) {
  http.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function unsetToken () {
  http.defaults.headers.common.Authorization = ''
}

export default function install (Vue, { store, router }) {
  interceptors(http, store, router)
  Object.defineProperty(Vue.prototype, '$http', {
    get () {
      return http
    }
  })
}
