export default {
  fetching: false,
  accessibility: false,
  fontSize: false,
  menuOpen: false,
  menuDeslogadoOpen: false,
  showOverlay: false,
  modalValidarCertidao: false,
  modalAtivo: null,
  empresaCorrente: null,
  modalNovaEmpresa: false,
  modalDefesaAutuacao: false,
  modalNovaEmpresaSolicitada: false,
  modalNovoAdministradorEmpresa: {
    show: false,
    resolve: undefined,
    reject: undefined
  },
  modalNovoAdministradorEmpresaSuccess: {
    show: false,
    pendente: false,
    nome: undefined,
    email: undefined
  },
  modalConfirm: {
    show: false,
    title: undefined,
    message: undefined,
    resolve: undefined,
    reject: undefined,
    btnConfirmLabel: undefined,
    btnCancelLabel: undefined
  },
  deferredPrompt: undefined,
  pwaInstalled: false,
  modalCSAT: false,
  avisoCookies: {
    show: true
  },
  mostrarAvisoManutencao: true
}
