import { http } from '@/support/http'
import store from '@/store/index'

function isModoEmpresa () {
  return store.state.empresaCorrente
}

export const carregaSolicitacoes = page => {
  const pageRequest = {
    filter: {
      texto: null,
      sistema: null
    },
    pageInfo: {
      size: 10,
      number: 0
    }
  }
  Object.assign(pageRequest, page)
  return http.post('solicitacao', pageRequest)
}

export const ultimaSolicitacao = sistema => {
  return http.get(`solicitacao/ultima/${sistema}`)
}

export const cancelarSolicitacao = id => {
  if (isModoEmpresa()) {
    return http.post(`solicitacao/cancel/${store.state.empresaCorrente.id}/${id}`)
  }
  return http.post(`solicitacao/cancel/${id}`)
}

export const abrirSolicitacao = solicitacaoDTO => {
  if (isModoEmpresa()) {
    return http.post(`solicitacao/add/${store.state.empresaCorrente.id}`, solicitacaoDTO)
  }
  return http.post('solicitacao/add/', solicitacaoDTO)
}

export const getSolicitacao = id => {
  if (isModoEmpresa()) {
    return http.get(`solicitacao/${store.state.empresaCorrente.id}/${id}`)
  }
  return http.get(`solicitacao/${id}`)
}
