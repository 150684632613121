
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vbclass from 'vue-body-class'
import vClickOutside from 'v-click-outside'
import Vuelidate from 'vuelidate'
import VueClipboard from 'vue-clipboard2'
import VueMq from 'vue-mq'
import httpPlugin from '@/support/http'
import { mixin } from './mixins/index'
import VueTheMask from 'vue-the-mask'
import './filters/filters'
import 'vue-toast-notification/dist/theme-sugar.css'
import { RECAPTCHA_SITE_KEY, GOOGLE_ANALYTICS_ID } from '@/config'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueAnalytics from 'vue-analytics'
import VPopover from 'vue-js-popover'
import Notifications from 'vue-notification'
import { tratarErro, readFileAsText } from './util/general-util'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Notifications)
Vue.use(vClickOutside)
Vue.use(vbclass, router)
Vue.use(Vuelidate)
Vue.use(VueClipboard)
Vue.use(VueTheMask)
Vue.use(VPopover, { tooltip: true })
Vue.use(httpPlugin, { store, router })
Vue.use(VueMq, {
  breakpoints: {
    mobile: 1024,
    desktop: Infinity
  }
})
Vue.use(VueReCaptcha, { siteKey: RECAPTCHA_SITE_KEY, lang: 'pt-BR' })
Vue.use(VueAnalytics, {
  id: GOOGLE_ANALYTICS_ID,
  autoTracking: {
    exception: true
  },
  router
})
Vue.mixin(mixin)

Vue.config.productionTip = false

/* Funções de notificação global */
Vue.prototype.$tratarErro = tratarErro

Vue.prototype.$trataErroArrayBuffer = async erro => {

  if (erro.response) {
    try {
      const data = erro.response.data

      const file = await readFileAsText(data)

      const result = JSON.parse(file)

      return tratarErro(result)
    } catch (readError) {
      return tratarErro({})
    }
  }
  return tratarErro({})
},

Vue.prototype.$success = function(title, text) {
  Vue.notify({
    group: 'global',
    type: 'success',
    title,
    text
  })
}

Vue.prototype.$warn = function(title, text) {
  Vue.notify({
    group: 'global',
    type: 'warn',
    title,
    text
  })
}

Vue.prototype.$error = function (title, error) {
  const erroTratado = Vue.prototype.$tratarErro(error)
  Vue.notify({
    group: 'global',
    type: 'error',
    title,
    text: erroTratado,
    duration: 10000
  })
  if (error) {
    console.error(error)
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
