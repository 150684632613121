export default {
  modalRequestDare: false,
  modalRequestCertificateReady: false,
  modalRequestCNHDefinitiva: false,
  modalRequestCNHRenovacaoConfirmacao: false,

  modalCertidaoSeguro: false,
  modalCertidaoBaixa: false,
  modalCrlve: false,
  modalCertidaoPropriedadeVeiculos: false,
  modalCertidaoRegistroCNH: false,

  modalRequestCNHRenovacao: false,
  modalRequestRenew: false,
  modalCiretranCav: false,
  modalDescricao: null,

  solicitacaoRecente: null,
  listaSolicitacoes: {
    content: [],
    totalElements: 0
  },
  idSolicitacao: null,
  formularioDefesaInfracao: {}
}
