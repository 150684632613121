import * as CryptoJS from 'crypto-js'

const generateRandomString = (length) => {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

const base64URL = (value) => {
  return value.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const generateCodeVerifier = () => {
  return generateRandomString(128)
}

export const generateCodeChallenge = (codeVerifier) => {
  return base64URL(CryptoJS.SHA256(codeVerifier))
}
