import Vue from 'vue'
import Router from 'vue-router'
import beforeEach from './beforeEach'
import { routes as routesViews } from '@/views'

Vue.use(Router)

const routes = [...routesViews]

const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-active-parent',
  linkExactActiveClass: 'is-active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

router.beforeEach(beforeEach)

export default router
