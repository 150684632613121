<template>
  <div
    id="app"
    :class="{ 'accessibility': accessibility, 'increase-font-size': fontSize, 'is-logged': isLogged, 'public': isPublicPage, 'manutencao' : isManutencao && mostrarAvisoManutencao, 'aviso-cookies': $store.state.avisoCookies.show, 'mobile': $media.mobile }"
  >
    <div v-if="isManutencao && mostrarAvisoManutencao" class="mensagem-manutencao" >
      <span v-if="System.statusSistema.titulo" class="d-block">{{System.statusSistema.titulo}}</span>
      <div>
        {{System.statusSistema.mensagemManutencao}}
        <Action v-if="isLogged" @click="setMostrarAvisoManutencao(false)" icon class="btn-close">
          <Icon slot="icon" name="close" size="medium" />
        </Action>
      </div>
    </div>
    <overlay v-show="showOverlay" />
    <v-header v-if="!hideHeader"></v-header>
    <main class="main">
      <router-view />
      <ModalValidarCertidao />
      <ModalProcessoConsulta />
    </main>
    <notifications group="global" position="bottom right" />
    <v-footer v-if="!hideFooter"></v-footer>
    <aviso-cookies/>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import AvisoCookies from './components/AvisoCookies.vue'
import AutenticacaoService from '@/domains/requests/services/AutenticacaoService'

export default {
  name: 'App',
  components: {
    Action: () => import('@/components/Action'),
    Icon: () => import('@/components/Icon'),
    vFooter: () => import('@/components/Footer'),
    vHeader: () => import('@/components/Header'),
    Overlay: () => import('@/components/Overlay'),
    ModalValidarCertidao: () => import('@/views/home/components/ModalValidarCertidao'),
    ModalProcessoConsulta: () =>
      import('@/views/home/components/ModalProcessoConsulta'),
    AvisoCookies
  },
  computed: {
    ...mapGetters(['isManutencao']),
    ...mapState(['fontSize', 'showOverlay', 'System', 'mostrarAvisoManutencao']),
    isIE () {
      return /* @cc_on!@*/ false || !!document.documentMode
    },
    isEdge () {
      return !this.isIE && !!window.StyleMedia
    },
    isOpera () {
      // eslint-disable-next-line
      return (
        // eslint-disable-next-line no-undef
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(' OPR/') >= 0
      )
    },
    isFirefox () {
      return typeof InstallTrigger !== 'undefined'
    },
    isSafari () {
      // eslint-disable-next-line
      return (
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === '[object SafariRemoteNotification]'
        // eslint-disable-next-line no-undef
        })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification))
      )
    },
    isChrome () {
      return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
    },
    isBlink () {
      return (this.isChrome || this.isOpera) && !!window.CSS
    },
    hideHeader () {
      return this.$route.meta.hideHeader || false
    },
    hideFooter () {
      return this.$route.meta.hideFooter || false
    }
  },
  watch: {
    '$store.state.avisoCookies' () {
      this.habilitarCookies()
    }
  },
  methods: {
    ...mapActions(['setPrompt', 'setPwaInstalled', 'setAndroid', 'setMostrarAvisoManutencao']),
    habilitarCookies () {
      window[`ga-disable-${process.env.VUE_APP_ANALYTICS}`] = !(!this.$store.state.avisoCookies.show && this.$store.state.avisoCookies.accept)
    },
    async carregarInformacoesUsuario () {
      if (!this.isLogged) return
      const dadosUsuario = await AutenticacaoService.minhasInformacoes()
      this.$store.dispatch('registraDados', dadosUsuario.data)
    }
  },
  mounted () {
    this.$store.dispatch('toggleOverlay', false)
    this.$store.dispatch('getStatusSistema')

    this.setPwaInstalled(typeof window.onbeforeinstallprompt === 'object')
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent Chrome 76 and later from showing the mini-infobar
      e.preventDefault()
      // Stash the event so it can be triggered later.
      this.setPrompt(e)
      this.setPwaInstalled(false)
    })

    if (
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone === true
    ) {
      this.setPwaInstalled(true)
      this.$ga.event('config', 'PWA', 'Instalado', 1)
    } else {
      this.$ga.event('config', 'PWA', 'Instalado', 0)
    }

    const urlParams = new URLSearchParams(window.location.search)
    const utmSource = urlParams.get('utm_source')

    if (utmSource === 'android') {
      this.setAndroid(true)
    }

    window.addEventListener('appinstalled', e => this.setPwaInstalled(true))

    this.habilitarCookies()
    this.carregarInformacoesUsuario()
  }
}
</script>

<style src="@/assets/styles/global.css"></style>
<style>
@import 'https://unpkg.com/balloon-css/balloon.min.css';
</style>
<style>
.vue-notification {
  font-size: 15px !important;
}

.notification-title {
  margin-bottom: 5px;
}
</style>
