import store from '@/store'

// Essas páginas são independentes de estar logado ou não
const ignoreAuthPages = ['404', 'confirmar-alteracao-email', 'confirmar-alteracao-email-empresarial', 'validate-email-processo']

const beforeEach = (to, from, next) => {
  const requiresAuth = to.meta.requiresAuth === true

  if (ignoreAuthPages.indexOf(to.name) > -1) {
    next()
    return
  }

  if(from.name == 'logingovbr' && !!window && !!window.opener) {
    window.opener.postMessage('popupFechado', '*');
    window.close(); // Fecha o modal
  }

  if (!requiresAuth) {
    if (!store.state.Auth.token) {
      next()
      return
    } else {
      let redirect = JSON.parse(localStorage.getItem('last-route-govbr')) || { name: 'dashboard' };
      localStorage.removeItem('last-route-govbr');
      store.dispatch('toggleOverlay', false)
      next(redirect);
    }
  }

  store
    .dispatch('checkUserToken')
    .then(() => {
      next()
      store.dispatch('toggleMenu', false)
    })
    .catch(() => {
      next({ name: 'login', params: { lastRoute: { path: to.path, query: to.query }}})
    })
}

export default beforeEach
