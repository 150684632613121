import { mapState, mapGetters } from 'vuex'
import { PUSH_SERVER_PUBLIC_KEY } from '@/config'
import { tratarErro, readFileAsText } from '@/util/general-util'
import { formataDataParaEnvio } from './methods'
import { generateCodeVerifier, generateCodeChallenge } from '@/util/crypto'

let govBRPopup = null;

function urlB64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const mixin = {
  data () {
    return {
      clientId: process.env.VUE_APP_CLIENT_ID_WSO2,
      urlProvider: process.env.VUE_APP_URL_WSO2,
      redirectUri: process.env.VUE_APP_WSO2_REDIRECT_URL
    }
  },
  computed: {
    ...mapGetters(['roleHabilitacao', 'roleVeiculo', 'roleAgendamento', 'roleBeta']),
    ...mapState(['Auth', 'accessibility', 'empresaCorrente']),
    isLogged () {
      return this.Auth.token !== null
    },
    isPublicPage () {
      return this.$route.matched.some(r => r.path === '/p')
    },
    $media () {
      return {
        mobile: this.$mq === 'mobile',
        desktop: this.$mq === 'desktop'
      }
    },
    iOS () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document) // iPad on iOS 13 detection
    }
  },
  methods: {
    trataErro (erro) {
      return tratarErro(erro)
    },
    /**
     * Usar este quando o axios está esperando um arraybuffer, porém vem um json de erro do servidor
     * @param {*} erro
     * @returns Mensagem de erro parseada
     */
    async trataErroArrayBuffer (erro) {
      if (erro.response) {
        try {
          const { data } = erro.response

          const file = await readFileAsText(data)

          const result = JSON.parse(file)

          return this.trataErro({ response: { data: result }})
        } catch (readError) {
          return this.trataErro({})
        }
      }
      return this.trataErro({})
    },
    isLiberado (itemMenu) {
      switch (itemMenu) {
        case 'license': return !this.empresaCorrente
        case 'agendamento': return this.roleAgendamento
        case 'vehicles': return true
        case 'certidoes': return true
        case 'infracoes': return true
        case 'consultas-publicas': return true
        default: return true
      }
    },
    formataDataParaEnvio,
    registraPush (habilitarNotificacaoPush) {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then(swRegistration =>
          swRegistration.pushManager.getSubscription().then(subscription => {
            if (!(subscription === null)) {
              if (habilitarNotificacaoPush) {
                this.salvarSubscription(subscription)
              } else {
                subscription
                  .unsubscribe()
                  .then(() => {
                    console.log('unsubscribed')
                  })
                  .catch('erro unsubscribed')
                  .finally(
                    this.$store
                      .dispatch('cancelarPush')
                      .then()
                      .catch(e => console.log('Erro em cancelarPush: ' + e.message))
                  )
              }
            } else {
              if (habilitarNotificacaoPush) {
                swRegistration.pushManager
                  .subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlB64ToUint8Array(PUSH_SERVER_PUBLIC_KEY)
                  })
                  .then(subscription => {
                    this.salvarSubscription(subscription)
                  })
                  .catch(e => console.log('Erro ao tentar subscription: ' + e.message))
              }
            }
          })
        )
      }
    },
    realizarLoginGovbr (self = false) {
      const codeVerifier = generateCodeVerifier()

      localStorage.setItem('app-code-verifier', codeVerifier)
      const codeChallenge = generateCodeChallenge(codeVerifier)
      const state = this.$route.params.lastRoute && `&state=${window.btoa(JSON.stringify(this.$route.params.lastRoute))}`
      const url = `${this.urlProvider}/oauth2/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=openid+email+phone+govbr_empresa` + state

      if(!this.iOS && !self) this.abrirGovBRPopup(url);
      else window.open(url, '_self');
    },
    salvarSubscription (subscription) {
      const payload = new FormData()
      payload.append('subscription', JSON.stringify(subscription))
      payload.append('userAgent', navigator.userAgent)
      this.$store
        .dispatch('salvarPush', payload)
        .then()
        .catch(e => console.log('Erro em salvarSubscription: ' + e.message))
    },
    downloadByteArray (reportName, resultByte) {
      var blob = new Blob([resultByte], { type: 'application/pdf' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      var fileName = reportName
      link.download = fileName

      /** Workaround firefoca */
      link.style.display = 'none'
      document.body.appendChild(link)
      /** Workaround firefoca */

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName)
      } else {
        link.click()
      }

      /** Workaround firefox */
      document.body.removeChild(link)
    },
    abrirGovBRPopup(url) {
      var largura = 420;
      var altura = 680;
      // Calcula a posição central para o pop-up
      var esquerda = (window.screen.width - largura) / 2;
      var topo = (window.screen.height - altura) / 2;

      const windowFeatures = `left=${esquerda},top=${topo},width=${largura},height=${altura}`;

      this.fecharGovBRPopup();
      govBRPopup = window.open(
        url, 
        '_blank',
        windowFeatures
      )

      if(esquerda != govBRPopup.screenLeft) {
        let moveToLeft = (-1* esquerda)+govBRPopup.screenLeft;
        govBRPopup.moveTo(moveToLeft,topo)
      }
      if (!govBRPopup || govBRPopup.closed || typeof govBRPopup.closed == 'undefined') {
        alert('O pop-up foi bloqueado pelo navegador.');
      }
    },
    fecharGovBRPopup() {
      if(!govBRPopup) return;
      govBRPopup.close();
      govBRPopup = null;
    },
    logouGovBR(event) {
      if (event.source === govBRPopup && event.data === 'popupFechado' && !govBRPopup.redirected) {
          govBRPopup.redirected = true;

          const { lastRoute } = this.$route.params
          if (lastRoute && lastRoute.path && lastRoute.path != '/login') localStorage.setItem('last-route-govbr', JSON.stringify(lastRoute));
          this.$router.go(0);
      }
    }
  },
  mounted() {
    window.removeEventListener('beforeunload', this.fecharGovBRPopup);
    window.addEventListener('beforeunload', this.fecharGovBRPopup);
    window.removeEventListener('message', this.logouGovBR);
    window.addEventListener('message', this.logouGovBR);
  }
}
