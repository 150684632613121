import { http } from '@/support/http'

export const procurarUsuario = (cpf) => {
  return http.get(`admin-empresa/${cpf}`)
}

export const vincularUsuario = (idUsuario, idEmpresa) => {
  return http.post(`admin-empresa/vincular/${idUsuario}/${idEmpresa}`)
}

export const convidarUsuario = (idEmpresa, data) => {
  return http.post(`admin-empresa/convidar/${idEmpresa}`, data)
}

export const carregaAdministradores = page => {
  const pageRequest = {
    filter: null,
    pageInfo: {
      size: 10,
      number: 0
    }
  }
  Object.assign(pageRequest, page)
  return http.post('admin-empresa/listar', pageRequest)
}

export const excluirConvite = (cpf, idEmpresa) => {
  return http.post(`admin-empresa/excluir-convite/${cpf}/${idEmpresa}`)
}

export const excluirAdministrador = (idUsusario, idEmpresa) => {
  return http.post(`admin-empresa/excluir-administrador/${idUsusario}/${idEmpresa}`)
}

export const listaConfiguracoesEmpresa = () => {
  return http.get(`admin-empresa/minhas-informacoes`)
}

export const alterarConfiguracoesDeNotificacao = (idEmpresa, data) => {
  return http.post(`admin-empresa/atualizar-notificacoes/${idEmpresa}`, data)
}
