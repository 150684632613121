import { http } from '@/support/http'

export const configurarNotificacoes = (notificacaoDTO) => {
  return http.post('usuario/notificacoes', notificacaoDTO)
}

export const configurarPermissaoConsultaTerceiros = (payload) => {
  return http.post('usuario/permissao-consulta-terceiros', payload)
}

export const requisitarAlteracaoEmail = (payload) => {
  return http.post('usuario/requisitar-alteracao-email', payload)
}

export const requisitarAlteracaoEmailEmpresarial = (payload) => {
  return http.post('usuario/requisitar-alteracao-email-empresarial', payload)
}

export const confirmarAlteracaoEmail = (payload) => {
  return http.post('usuario/confirmar-alteracao-email', payload)
}

export const confirmarAlteracaoEmailEmpresarial = (payload) => {
  return http.post('usuario/confirmar-alteracao-email-empresarial', payload)
}

export const atualizarDadosContato = () => {
  return http.post('usuario/atualizar-dados-contato')
}

export const dadosContatoAtualizados = () => {
  return http.get('usuario/dados-contato-atualizados')
}

export const roles = () => {
  return http.get('usuario/roles')
}

export const me = () => {
  return http.get('usuario/me')
}

export const getServerDate = () => {
  return http.get('usuario/date')
}

export const enderecoCiretran = (payload) => {
  return http.post('usuario/endereco-map', payload)
}

export const alterarTelefone = (payload) => {
  return http.post('usuario/alterar-telefone', payload)
}
