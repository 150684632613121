import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_LISTA_VALORES] (state, value) {
    state.valores = value
  },
  [TYPES.STATUS_SISTEMA] (state, value) {
    state.statusSistema = value
  }
}
