const Logged = () => import('./components/Logged')
const Dashboard = () => import('./components/Dashboard')
const Vehicles = () => import('./components/Vehicles')
const ConsultaDossieVeiculo = () => import('./components/ConsultaDossieVeiculo')
const EmitirCrlvE = () => import('./components/EmitirCrlvE')
const DossieVeiculoResultado = () => import('./components/DossieVeiculoResultado')
const ConsultaResponsabilidadeIpva = () => import('./components/ConsultaResponsabilidadeIpva')
const ResultadoResponsabilidadeIpva = () => import('./components/ResultadoResponsabilidadeIpva')
const ConsultaDebitosPessoa = () => import('./components/ConsultaDebitosPessoa')
const ResultadoDebitosPessoa = () => import('./components/ResultadoDebitosPessoa')
const Certificates = () => import('./components/Certificates')
const Requests = () => import('./components/Requests')
const Ajuda = () => import('./components/Ajuda')
const License = () => import('./components/License')
const Profile = () => import('./components/Profile')
const RenewCnh = () => import('./components/RenewCnh')
const Agendamento = () => import('./components/Agendamento')
const Empresa = () => import('./components/Empresa')
const Infracoes = () => import('./components/Infracoes')
const ProcessoInfracao = () => import('./components/ProcessoInfracao')
const ProcessoPenalidade = () => import('./components/ProcessoPenalidade')
const ConsultaPontuacao = () => import('./components/ConsultaPontuacao')
const ResultadoPontuacao = () => import('./components/ResultadoPontuacao')
const ConsultaProva = () => import('./components/ConsultaProva')
const ResultadoProva = () => import('./components/ResultadoProva')
const SegundaVia = () => import('./components/SegundaVia')
const RequestPid = () => import('./components/RequestPid')

export default [
  {
    name: 'logged',
    path: '/app',
    redirect: { name: 'dashboard' },
    component: Logged,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        name: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
        meta: {
          bodyClass: 'page-dashboard',
          requiresAuth: true
        }
      },
      {
        name: 'license',
        path: '/habilitacao',
        component: License,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'renew',
        path: '/renovacao',
        component: RenewCnh,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'requests',
        path: '/solicitacoes',
        component: Requests,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'vehicles',
        path: '/veiculos',
        component: Vehicles,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'certidoes',
        path: '/certidoes',
        component: Certificates,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'consulta-dossie-veiculo',
        path: '/consulta-dossie-veiculo',
        component: ConsultaDossieVeiculo,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'emitir-crlv-e',
        path: '/emitir-crlv-e',
        component: EmitirCrlvE,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'dossie-completo',
        path: '/dossie-completo',
        component: DossieVeiculoResultado,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'infracoes',
        path: '/infracoes',
        component: Infracoes,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'consulta-pontuacao',
        path: '/consulta-pontuacao',
        component: ConsultaPontuacao,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'resultado-pontuacao',
        path: '/resultado-pontuacao',
        component: ResultadoPontuacao,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'consulta-responsabilidade-ipva',
        path: '/consulta-responsabilidade-ipva',
        component: ConsultaResponsabilidadeIpva,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'resultado-responsabilidade-ipva',
        path: '/resultado-responsabilidade-ipva',
        component: ResultadoResponsabilidadeIpva,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'listar-debitos-pessoa',
        path: '/listar-debitos-pessoa',
        component: ConsultaDebitosPessoa,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'resultado-debitos-pessoa',
        path: '/resultado-debitos-pessoa',
        component: ResultadoDebitosPessoa,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'consulta-prova',
        path: '/consulta-prova',
        component: ConsultaProva,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'resultado-prova',
        path: '/resultado-prova',
        component: ResultadoProva,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'segunda-via',
        path: '/segunda-via',
        component: SegundaVia,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'request-pid',
        path: '/request-pid',
        component: RequestPid,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'profile',
        path: '/profile',
        component: Profile,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'support',
        path: '/ajuda',
        component: Ajuda,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'agendamento',
        path: '/agendamento',
        component: Agendamento,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'empresa',
        path: '/empresa',
        component: Empresa,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'acompanhar-processo-infracao',
        path: '/acompanhar-processo-infracao',
        component: ProcessoInfracao,
        props: route => ({ ...route.params }),
        meta: {
          titulo: 'Recurso infração',
          subtitulo: 'Acompanhamento e Defesa On-line',
          requiresAuth: true
        }
      },
      {
        name: 'acompanhar-processo-penalidade',
        path: '/acompanhar-processo-penalidade',
        component: ProcessoPenalidade,
        props: route => ({ ...route.params }),
        meta: {
          titulo: 'Processo administrativo',
          subtitulo: 'Acompanhamento e Defesa On-line',
          requiresAuth: true
        }
      }
    ]
  }
]
