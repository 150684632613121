import Auth from '@/domains/auth/vuex'
import Requests from '@/domains/requests/vuex'
import Profile from '@/domains/profile/vuex'
import System from '@/domains/system/vuex'
import Vehicles from '@/domains/vehicles/vuex'

const vuex = {
  Auth,
  Requests,
  Profile,
  System,
  Vehicles
}
const keys = Object.keys(vuex)
const modules = keys.reduce((acc, key) => ({ ...acc, [key]: vuex[key].module }), {})

export default { modules }
