export default {
  token: null,
  user: {
    nome: null
  },
  nomesPid: [],
  roles: [],
  enderecosAgendamento: [],
  pesquisaSatisfacao: {
    content: [],
    totalElements: 0
  },
  idToken: null
}
