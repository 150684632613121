import * as TYPES from './mutations-types'
import * as services from '@/domains/vehicles/services/VeiculoService'

export const carregarVeiculos = ({ commit }, payload) => {
  return services.carregaVeiculos(payload)
    .then(({ data }) => {
      commit(TYPES.SET_LISTA_VEICULOS, data)
      return data
    })
}

export const vehicleCrlve = ({ commit }, veiculo) => {
  return services.solicitarLicenciamento(veiculo)
    .then(({ data }) => {
      return data
    })
}
