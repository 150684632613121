import Vue from 'vue'
import store from '@/store'
const Home = () => import('./components/Home')
const Public = () => import('./components/Public')
const Login = () => import('./components/Login')
const LoginGovBr = () => import('./components/LoginGovBr')
const ValidateEmail = () => import('./components/ValidateEmail')
const ValidateEmailProcesso = () => import('./components/ValidateEmailProcesso')
const EmailReenvio = () => import('./components/EmailReenvio')
const ForgotPasswordGovBr = () => import('./components/ForgotPasswordGovBr')
const NewPassword = () => import('./components/NewPassword')

export default [
  {
    name: 'home',
    path: '/',
    component: Home,
    redirect: { name: 'login' },
    alias: '/index.html',
    meta: {
      bodyClass: 'page-home',
      requiresAuth: false
    },
    children: [
      {
        name: 'login',
        path: 'login',
        component: Login
      },
      {
        name: 'logingovbr',
        path: 'logingovbr',
        component: LoginGovBr
      },
      {
        name: 'validate-email',
        path: 'validar-email/:token',
        component: ValidateEmail
      },
      {
        name: 'validate-email-processo',
        path: 'validar-email-processo/:token',
        component: ValidateEmailProcesso
      },
      {
        name: 'email-reenvio',
        path: 'email-reenvio/:id',
        component: EmailReenvio
      },
      {
        name: 'forgot-password-govbr',
        path: 'esqueci-senha-govbr',
        component: ForgotPasswordGovBr
      },
      {
        name: 'new-password',
        path: 'nova-senha/:token',
        component: NewPassword
      }
    ]
  },
  {
    name: 'modal-processo',
    path: '/processo',
    component: Home,
    redirect: { name: 'login', params: { modal: 'ProcessoConsulta' }},
    alias: '/index.html',
    meta: {
      bodyClass: 'page-home',
      requiresAuth: false
    }
  },
  {
    name: 'public',
    path: '/p',
    component: Public,
    meta: {
      requiresAuth: false
    },
    children: [
    ]
  },
  {
    name: 'logout',
    path: '/logout',
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      store.dispatch('logout')
      return next({ name: 'login' })
    }
  },
  {
    name: 'confirmar-alteracao-email',
    path: '/confirmar-alteracao-email/:token',
    beforeEnter: (to, from, next) => {
      const payload = new FormData()
      payload.append('token', to.params.token)
      store
        .dispatch('confirmarAlteracaoEmail', payload)
        .then(
          sucesso => {
            Vue.prototype.$success('Endereço de email alterado com sucesso')
            store.dispatch('logout')
            return next({ name: 'login' })
          },
          erro => {
            Vue.prototype.$error('Erro alteração e-mail', erro)
          }
        )
        .finally(() => {
          return next({ name: 'login' })
        })
    }
  },
  {
    name: 'confirmar-alteracao-email-empresarial',
    path: '/confirmar-alteracao-email-empresarial/:token',
    beforeEnter: (to, from, next) => {
      const payload = new FormData()
      payload.append('token', to.params.token)
      store
        .dispatch('confirmarAlteracaoEmailEmpresarial', payload)
        .then(
          sucesso => {
            Vue.prototype.$success('Endereço de email alterado com sucesso')
            store.dispatch('logout')
            return next({ name: 'login' })
          },
          erro => {
            Vue.prototype.$error('Erro alteração e-mail', erro)
          }
        )
        .finally(() => {
          return next({ name: 'login' })
        })
    }
  }
]
