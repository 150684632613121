<template>
  <div class="aviso-cookies" v-if='$store.state.avisoCookies.show' :class="{'mobile': $media.mobile}">
    <span
      >Nós usamos cookies para melhorar a sua experiência em nosso site coletando dados anônimos de navegação. Esses dados estarão disponíveis apenas para nossos analistas. Ao utilizar nosso site, assumiremos que você concorda com tal monitoramento.</span
    >
    <div class="botoes">
      <action text="Ok" filled color="primary" small @click="$store.dispatch('acceptCookies', true)"/>
      <action v-if='false' text="Rejeitar" filled color="primary" small @click="$store.dispatch('acceptCookies', false)"/>
    </div>
  </div>
</template>
<script>
import Action from './Action.vue'
export default {
  components: {
    Action
  }
}
</script>
<style scoped>
.aviso-cookies {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  height: 80px;
  width: 90%;
  bottom: 10px;
  left: 10px;
  padding: 10px;
  box-shadow: 0 0 1px 1px gray;
  border-radius: 5px;
  z-index: 1;
}

.aviso-cookies span {
  font-size: 1.5em;
  margin-right: 10px;
}

.aviso-cookies.mobile {
  width: 72%;
  height: 180px;
}

.botoes {
  display: flex;
}

.aviso-cookies.mobile .botoes {
  flex-direction: column;
  font-size: 0.7em;
}

.botoes > * {
  margin-right: 5px;
}

.aviso-cookies.mobile .botoes > * {
  margin-right: 0;
  margin-bottom: 5px;
}
</style>
