import { http } from '@/support/http'

class AutenticacaoService {
  constructor () {
    this.baseUrl = '/auth'
  }

  minhasInformacoes () {
    return http.get(`${this.baseUrl}/minhas-informacoes`)
  }
}

export default new AutenticacaoService()
