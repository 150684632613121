export default {
  isManutencao: (state, getters, rootState) => {
    const ciretran = (((rootState.Auth || { user: {}}).user || { ciretranEnderecos: [] }).ciretranEnderecos || [])[0]
    const ciretransManutencao = (state.statusSistema.ciretransManutencao || [])

    const manutencaoPorCiretran = ciretran !== undefined && ciretransManutencao.indexOf(ciretran.idCiretran) !== -1

    return state.statusSistema.manutencao || manutencaoPorCiretran
  },
  isManutencaoCnh: state => {
    return (state.statusSistema.tipos || []).indexOf('CNH') > -1
  },
  isManutencaoKafka: state => {
    return (state.statusSistema.tipos || []).indexOf('KAFKA') > -1
  },
  isManutencaoSerpro: state => {
    return (state.statusSistema.tipos || []).indexOf('SERPRO') > -1
  },
  isManutencaoWso2: state => {
    return (state.statusSistema.tipos || []).indexOf('WSO2') > -1
  },
  isManutencaoGovbr: state => {
    return (state.statusSistema.tipos || []).indexOf('GOVBR') > -1
  },
  isManutencaoDetrannet: state => {
    return (state.statusSistema.tipos || []).indexOf('DETRANNET') > -1
  }
}
