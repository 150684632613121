export const MAIN_SET_FETCHING = 'MAIN_SET_FETCHING'
export const MAIN_SET_ACCESSIBILITY = 'MAIN_SET_ACCESSIBILITY'
export const MAIN_SET_FONTSIZE = 'MAIN_SET_FONTSIZE'
export const MAIN_TOGGLE_MENU = 'MAIN_TOGGLE_MENU'
export const MAIN_TOGGLE_MENU_DESLOGADO = 'MAIN_TOGGLE_MENU_DESLOGADO'
export const MAIN_TOGGLE_OVERLAY = 'MAIN_TOGGLE_OVERLAY'
export const MODAL_VALIDACAO_CERTIDAO = 'MODAL_VALIDACAO_CERTIDAO'
export const SET_MODAL_ATIVO = 'SET_MODAL_ATIVO'
export const MODAL_PROCESSO_CONSULTA = 'MODAL_PROCESSO_CONSULTA'
export const MODAL_PROCESSO_SUSPENSAO_RECURSO = 'MODAL_PROCESSO_SUSPENSAO_RECURSO'
export const SET_EMPRESA_CORRENTE = 'SET_EMPRESA_CORRENTE'
export const SET_CONFIGURACAO_NOTIFICACOES_EMPRESA = 'SET_CONFIGURACAO_NOTIFICACOES_EMPRESA'
export const MODAL_NOVA_EMPRESA = 'MODAL_NOVA_EMPRESA'
export const MODAL_DEFESA_AUTUACAO = 'MODAL_DEFESA_AUTUACAO'
export const MODAL_NOVA_EMPRESA_SOLICITADA = 'MODAL_NOVA_EMPRESA_SOLICITADA'
export const MODAL_NOVO_ADMINISTRADOR_EMPRESA = 'MODAL_NOVO_ADMINISTRADOR_EMPRESA'
export const MODAL_NOVO_ADMINISTRADOR_EMPRESA_SUCCESS = 'MODAL_NOVO_ADMINISTRADOR_EMPRESA_SUCCESS'
export const MODAL_CONFIRM = 'MODAL_CONFIRM'
export const MODAL_CONFIRM_OK = 'MODAL_CONFIRM_OK'
export const MODAL_CONFIRM_CANCEL = 'MODAL_CONFIRM_CANCEL'
export const DEFERRED_PROMPT = 'DEFERRED_PROMPT'
export const DEFERRED_PROMPT_SHOW = 'DEFERRED_PROMPT_SHOW'
export const PWA_INSTALLED = 'PWA_INSTALLED'
export const ANDROID_INSTALLED = 'ANDROID_INSTALLED'
export const IS_COMPANY = 'IS_COMPANY'
export const MODAL_CSAT = 'MODAL_CSAT'
export const ATUALIZAR_PESQUISA_SATISFACAO = '🔃 ATUALIZAR_PESQUISA_SATISFACAO'
export const AVISO_COOKIES = 'AVISO_COOKIES'
export const MOSTRAR_AVISO_MANUTENCAO = 'MOSTRAR_AVISO_MANUTENCAO'
