import * as TYPES from './mutations-types'
import * as services from '@/domains/requests/services/SolicitacaoService'
import * as administradorEmpresaService from '@/domains/requests/services/AdministradorEmpresaService'
import * as habilitacaoService from '@/domains/requests/services/HabilitacaoService'

export const setModalRequestDare = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_REQUEST_DARE, value)
}

export const setModalRequestCertificateReady = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_REQUEST_CERTIFICATE_READY, value)
}

export const setModalRequestCNHDefinitiva = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_REQUEST_CNH_DEFINITIVA, value)
}

export const setModalCertidaoSeguro = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CERTIDAO_SEGURO, value)
}

export const setModalCertidaoBaixa = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CERTIDAO_BAIXA, value)
}

export const setModalCertidaoPropriedadeVeiculos = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CERTIDAO_PROPRIEDADE_VEICULOS, value)
}

export const setModalCertidaoRegistroCNH = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CERTIDAO_REGISTRO_CNH, value)
}

export const setModalRequestCNHRenovacao = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_REQUEST_CNH_RENOVACAO, value)
}

export const setModalRequestRenew = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_REQUEST_RENEW, value)
}

export const setModalCiretranCav = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CIRETRAN_CAV, value)
}

export const setModalDescricao = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_DESCRICAO, value)
}

export const setModalRequestCNHRenovacaoConfirmacao = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_REQUEST_CNH_RENOVACAO_CONFIRMACAO, value)
}

export const setSolicitacaoRecente = ({ commit }, solicitacao) => {
  commit(TYPES.SET_SOLICITACAO_RECENTE, solicitacao)
  commit(TYPES.ATUALIZAR_SOLICITACAO, solicitacao)
}

export const atualizarSolicitacao = ({ commit }, solicitacao) => {
  commit(TYPES.ATUALIZAR_SOLICITACAO, solicitacao)
}

export const setIdSolicitacao = ({ commit }, value) => {
  commit(TYPES.SET_ID_SOLICITACAO, value)
}

export const carregarSolicitacoes = ({ commit }, payload) => {
  return services.carregaSolicitacoes(payload)
    .then(({ data }) => {
      commit(TYPES.SET_LISTA_SOLICITACOES, data)
      return data
    })
}

export const carregarAdministradores = ({ commit }, payload) => {
  return administradorEmpresaService.carregaAdministradores(payload)
    .then(({ data }) => {
      return data
    })
}

export const ultimaSolicitacao = ({ commit }, sistema) => {
  return services.ultimaSolicitacao(sistema)
    .then(({ data }) => {
      return data
    })
}

export const cancelarSolicitacao = ({ commit }, solicitacao) => {
  return services.cancelarSolicitacao(solicitacao.idSolicitacao)
    .then(({ data }) => {
      commit(TYPES.ATUALIZAR_SOLICITACAO, data)
      return data
    })
}

export const abrirSolicitacao = ({ commit }, solicitacao) => {
  return services.abrirSolicitacao(solicitacao)
    .then(({ data }) => {
      commit(TYPES.ATUALIZAR_SOLICITACAO, data)
      return data
    })
}

export const getSolicitacao = ({ commit }, id) => {
  return services.getSolicitacao(id)
    .then(({ data }) => {
      commit(TYPES.ATUALIZAR_SOLICITACAO, data)
      return data
    })
}

export const buscarDadosRenovacaoCnh = ({ commit }) => {
  return habilitacaoService.buscarDadosRenovacaoCnh()
    .then(({ data }) => {
      return data
    })
}

export const salvarFormularioTemporariamente = ({ commit }, value) => {
  commit(TYPES.SET_DADOS_FORMULARIO_DEFESA_INFRACAO, value)
}
