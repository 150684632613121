import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_MODAL_SOCIAL_NAME] (state, value) {
    state.modalSocialName = value
  },

  [TYPES.SET_MODAL_CHANGE_PASSWORD] (state, value) {
    state.modalChangePassword = value
  },

  [TYPES.SET_MODAL_CHANGE_EMAIL] (state, value) {
    state.modalChangeEmail = value
  },

  [TYPES.SET_MODAL_CHANGE_TEL] (state, value) {
    state.modalChangeTel = value
  },

  [TYPES.SET_MODAL_CONFIGURAR_NOTIFICACOES] (state, value) {
    state.modalConfigurarNotificacoes = value
  },

  [TYPES.SET_MODAL_CONFIGURAR_PRIVACIDADE] (state, value) {
    state.modalConfigurarPrivacidade = value
  },

  [TYPES.SET_MODAL_ATUALIZAR_DADOS_CNH_DIGITAL] (state, value) {
    state.modalAtualizarDadosCnhDigital = value
  }
}
