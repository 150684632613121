export const SET_MODAL_REQUEST_CNH_DEFINITIVA = 'SET_MODAL_REQUEST_CNH_DEFINITIVA'
export const SET_MODAL_REQUEST_DARE = 'SET_MODAL_REQUEST_DARE'
export const SET_MODAL_REQUEST_CERTIFICATE_READY = 'SET_MODAL_REQUEST_CERTIFICATE_READY'
export const SET_SOLICITACAO_RECENTE = 'SET_SOLICITACAO_RECENTE'
export const SET_ID_SOLICITACAO = 'SET_ID_SOLICITACAO'
export const ATUALIZAR_SOLICITACAO = '🔃 ATUALIZAR_SOLICITACAO'
export const SET_LISTA_SOLICITACOES = '✔️ SET_LISTA_SOLICITACOES'
export const SET_MODAL_CERTIDAO_SEGURO = 'SET_MODAL_CERTIDAO_SEGURO'
export const SET_MODAL_CERTIDAO_BAIXA = 'SET_MODAL_CERTIDAO_BAIXA'
export const SET_MODAL_CRLVE = 'SET_MODAL_CRLVE'
export const SET_MODAL_CERTIDAO_PROPRIEDADE_VEICULOS = 'SET_MODAL_CERTIDAO_PROPRIEDADE_VEICULOS'
export const SET_MODAL_CERTIDAO_REGISTRO_CNH = 'SET_MODAL_CERTIDAO_REGISTRO_CNH'
export const SET_MODAL_REQUEST_CNH_RENOVACAO = 'SET_MODAL_REQUEST_CNH_RENOVACAO'
export const SET_MODAL_REQUEST_CNH_RENOVACAO_CONFIRMACAO = 'SET_MODAL_REQUEST_CNH_RENOVACAO_CONFIRMACAO'
export const SET_MODAL_REQUEST_RENEW = 'SET_MODAL_REQUEST_RENEW'
export const SET_MODAL_CIRETRAN_CAV = 'SET_MODAL_CIRETRAN_CAV'
export const SET_MODAL_DESCRICAO = 'SET_MODAL_DESCRICAO'
export const SET_DADOS_FORMULARIO_DEFESA_INFRACAO = 'SET_DADOS_FORMULARIO_DEFESA_INFRACAO'
