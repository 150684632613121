import * as TYPES from './mutations-types'
import * as _ from 'lodash'

export default {
  [TYPES.SET_MODAL_REQUEST_DARE] (state, value) {
    state.modalRequestDare = value
  },

  [TYPES.SET_MODAL_REQUEST_CERTIFICATE_READY] (state, value) {
    state.modalRequestCertificateReady = value
  },

  [TYPES.SET_MODAL_REQUEST_CNH_DEFINITIVA] (state, value) {
    state.modalRequestCNHDefinitiva = value
  },

  [TYPES.SET_MODAL_CERTIDAO_SEGURO] (state, value) {
    state.modalCertidaoSeguro = value
  },

  [TYPES.SET_MODAL_CERTIDAO_BAIXA] (state, value) {
    state.modalCertidaoBaixa = value
  },

  [TYPES.SET_MODAL_CERTIDAO_PROPRIEDADE_VEICULOS] (state, value) {
    state.modalCertidaoPropriedadeVeiculos = value
  },

  [TYPES.SET_MODAL_CRLVE] (state, value) {
    state.modalCrlve = value
  },

  [TYPES.SET_MODAL_CERTIDAO_REGISTRO_CNH] (state, value) {
    state.modalCertidaoRegistroCNH = value
  },

  [TYPES.SET_MODAL_REQUEST_CNH_RENOVACAO] (state, value) {
    state.modalRequestCNHRenovacao = value
  },

  [TYPES.SET_MODAL_REQUEST_CNH_RENOVACAO_CONFIRMACAO] (state, value) {
    state.modalRequestCNHRenovacaoConfirmacao = value
  },

  [TYPES.SET_MODAL_REQUEST_RENEW] (state, value) {
    state.modalRequestRenew = value
  },

  [TYPES.SET_MODAL_CIRETRAN_CAV] (state, value) {
    state.modalCiretranCav = value
  },

  [TYPES.SET_MODAL_DESCRICAO] (state, value) {
    state.modalDescricao = value
  },

  [TYPES.SET_SOLICITACAO_RECENTE] (state, solicitacao) {
    state.solicitacaoRecente = solicitacao
  },

  [TYPES.ATUALIZAR_SOLICITACAO] (state, solicitacao) { // Remove uma solicitação com mesmo id (se houver id), e põe ela na lista novamente
    if (solicitacao.idSolicitacao) {
      _.remove(state.listaSolicitacoes.content, function (s) { return s.idSolicitacao === solicitacao.idSolicitacao })
    }
    state.listaSolicitacoes.content.push(solicitacao)
  },

  [TYPES.SET_LISTA_SOLICITACOES] (state, lista) {
    state.listaSolicitacoes = lista
  },

  [TYPES.SET_ID_SOLICITACAO] (state, value) {
    state.idSolicitacao = value
  },

  [TYPES.SET_DADOS_FORMULARIO_DEFESA_INFRACAO] (state, { instancia, valores, protocolo }) {
    state.formularioDefesaInfracao[protocolo] = { ...state.formularioDefesaInfracao[protocolo], ...{ [instancia]: valores }}
  }
}
