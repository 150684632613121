import * as TYPES from './types'
import { event } from 'vue-analytics'
import * as _ from 'lodash'

/* eslint-disable no-param-reassign */
export default {
  [TYPES.MAIN_SET_FETCHING] (state, fetching) {
    state.fetching = fetching
  },
  [TYPES.MAIN_SET_ACCESSIBILITY] (state) {
    state.accessibility = !state.accessibility
  },
  [TYPES.MAIN_SET_FONTSIZE] (state) {
    state.fontSize = !state.fontSize
  },
  [TYPES.MAIN_TOGGLE_MENU] (state, value) {
    state.menuOpen = value
  },
  [TYPES.MAIN_TOGGLE_MENU_DESLOGADO] (state, value) {
    state.menuDeslogadoOpen = value
  },
  [TYPES.SET_EMPRESA_CORRENTE] (state, value) {
    state.empresaCorrente = value
  },
  [TYPES.IS_COMPANY] (state, value) {
    state.isCompany = value
  },
  [TYPES.SET_CONFIGURACAO_NOTIFICACOES_EMPRESA] (state, value) {
    state.empresaCorrente = Object.assign(state.empresaCorrente, value)
  },
  [TYPES.MAIN_TOGGLE_OVERLAY] (state, value) {
    state.showOverlay = value
  },
  [TYPES.MODAL_VALIDACAO_CERTIDAO] (state, mostrar) {
    state.modalValidarCertidao = mostrar
  },
  [TYPES.MODAL_DEFESA_AUTUACAO] (state, mostrar) {
    state.modalDefesaAutuacao = mostrar
  },
  [TYPES.SET_MODAL_ATIVO] (state, modal) {
    state.modalAtivo = modal
  },
  [TYPES.MODAL_NOVA_EMPRESA] (state, mostrar) {
    state.modalNovaEmpresa = mostrar
  },
  [TYPES.MODAL_NOVA_EMPRESA_SOLICITADA] (state, mostrar) {
    state.modalNovaEmpresaSolicitada = mostrar
  },
  [TYPES.MODAL_NOVO_ADMINISTRADOR_EMPRESA] (state, values) {
    if (!values && state.modalNovoAdministradorEmpresa.reject) {
      state.modalNovoAdministradorEmpresa.reject()
      values = {}
    }
    state.modalNovoAdministradorEmpresa = values
  },
  [TYPES.MODAL_CONFIRM] (state, value) {
    state.modalConfirm = value
  },
  [TYPES.MODAL_CONFIRM_OK] (state) {
    const resolve = state.modalConfirm.resolve
    state.modalConfirm = {}
    if (resolve) {
      resolve()
    }
  },
  [TYPES.MODAL_CONFIRM_CANCEL] (state, value) {
    const reject = state.modalConfirm.reject
    state.modalConfirm = {}
    if (reject) {
      reject(value)
    }
  },
  [TYPES.MODAL_NOVO_ADMINISTRADOR_EMPRESA_SUCCESS] (state, values) {
    if (values.show && state.modalNovoAdministradorEmpresa.resolve) {
      state.modalNovoAdministradorEmpresa.resolve()
      state.modalNovoAdministradorEmpresa = {}
    }
    state.modalNovoAdministradorEmpresaSuccess = values
  },

  [TYPES.DEFERRED_PROMPT] (state, mostrar) {
    state.deferredPrompt = mostrar
  },
  [TYPES.DEFERRED_PROMPT_SHOW] (state) {
    if (state.deferredPrompt) {
      state.deferredPrompt.userChoice.then(choiceResult => {
        event('config', 'PWA', 'aceite', choiceResult.outcome === 'accepted' ? 1 : 0)
      })
      state.deferredPrompt.prompt()
    }
  },
  [TYPES.PWA_INSTALLED] (state, mostrar) {
    state.pwaInstalled = mostrar
  },
  [TYPES.ANDROID_INSTALLED] (state, mostrar) {
    state.androidInstalled = mostrar
  },
  resetState (state) {
    Object.assign(state.Auth, {
      token: null,
      idToken: null,
      user: null,
      nomesPid: [],
      roles: [],
      usuarioNovo: null,
      enderecosAgendamento: [],
      pesquisaSatisfacao: []
    })

    Object.assign(state.Requests, {
      modalRequestDare: false,
      modalRequestCertificateReady: false,
      modalRequestCNHDefinitiva: false,
      modalRequestCancel: { mostrar: false, cancelar: false },
      solicitacaoRecente: null,
      formularioDefesaInfracao: {},
      listaSolicitacoes: {
        content: [],
        totalElements: 0
      },
      configuracoesNotificacaoEmpresa: {},
      idSolicitacao: null,
      modalConfirm: {},
      modalNovoAdministradorEmpresaSuccess: {},
      empresaCorrente: null,
      deferredPrompt: undefined,
      pwaInstalled: false
    })

    Object.assign(state, {
      empresaCorrente: null,
      modalAtivo: null,
      mostrarAvisoManutencao: true
    })
  },

  [TYPES.MODAL_CSAT] (state, mostrar) {
    state.modalCSAT = mostrar
  },

  [TYPES.ATUALIZAR_PESQUISA_SATISFACAO] (state, pesquisaSatisfacao) { // Remove uma pesquisaSatisfacao com mesmo id (se houver id)
    if (pesquisaSatisfacao.id) {
      _.remove(state.Auth.pesquisaSatisfacao, function (p) { return p.id === pesquisaSatisfacao.id })
    }
  },

  [TYPES.AVISO_COOKIES] (state, accept) {
    state.avisoCookies = { accept, show: false }
  },

  [TYPES.MOSTRAR_AVISO_MANUTENCAO] (state, value) {
    state.mostrarAvisoManutencao = value
  }
}
