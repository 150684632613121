import * as TYPES from './mutations-types'

export default {
  [TYPES.SET_TOKEN] (state, values) {
    state.token = values.token
  },
  [TYPES.SET_LOGGED_USER] (state, values) {
    state.user = values.user
  },
  [TYPES.SET_NOMES_PID] (state, values) {
    state.nomesPid = values.nomesPid
  },
  [TYPES.SET_ROLES] (state, values) {
    state.roles = values.roles
  },
  [TYPES.SET_ENDERECOS_AGENDAMENTO] (state, value) {
    state.enderecosAgendamento = value
  },
  [TYPES.SET_USUARIO_NOVO] (state, value) {
    state.usuarioNovo = value
  },
  [TYPES.SET_PESQUISA_SATISFACAO] (state, value) {
    state.pesquisaSatisfacao = value
  },
  [TYPES.ID_TOKEN] (state, value) {
    state.idToken = value
  }
}
