import * as TYPES from './mutations-types'
import * as services from '@/domains/requests/services/UsuarioService'
import { requestUntilConditionMet } from '@/util/general-util'
import { parseISO as parseDate } from 'date-fns'

export const configurarNotificacoes = ({ dispatch }, payload) => {
  return services.configurarNotificacoes(payload)
    .then(({ data }) => {
      return data
    })
}

export const configurarPermissaoConsultaTerceiros = ({ dispatch }, payload) => {
  return services.configurarPermissaoConsultaTerceiros(payload)
    .then(({ data }) => {
      return data
    })
}

export const requisitarAlteracaoEmail = ({ dispatch }, payload) => {
  return services.requisitarAlteracaoEmail(payload)
    .then(({ data }) => {
      return data
    })
}

export const requisitarAlteracaoEmailEmpresarial = ({ dispatch }, payload) => {
  return services.requisitarAlteracaoEmailEmpresarial(payload)
    .then(({ data }) => {
      return data
    })
}

export const confirmarAlteracaoEmail = ({ dispatch }, payload) => {
  return services.confirmarAlteracaoEmail(payload)
    .then(({ data }) => {
      return data
    })
}

export const confirmarAlteracaoEmailEmpresarial = ({ dispatch }, payload) => {
  return services.confirmarAlteracaoEmailEmpresarial(payload)
    .then(({ data }) => {
      return data
    })
}

export const verificarDadosContatoRecemAtualizados = () => {
  return requestUntilConditionMet(services.dadosContatoAtualizados, (retorno) => retorno.data, 4, 1500)
}

export const atualizarDadosContato = ({ dispatch }) => {
  dispatch('toggleOverlay', true)
  return services.atualizarDadosContato().then(
    sucesso => verificarDadosContatoRecemAtualizados(),
    erro => {
      console.error(erro)
      return Promise.reject(erro)
    }
  ).finally(() => {
    dispatch('toggleOverlay', false)
  })
}

export const alterarTelefone = ({ dispatch }, payload) => {
  return services.alterarTelefone(payload)
    .then(({ data }) => {
      return data
    })
}

export const getServerDate = () => {
  return services.getServerDate()
    .then(({ data }) => {
      let convertedDate = parseDate(data)
      return convertedDate
    })
}

export const setModalSocialName = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_SOCIAL_NAME, value)
}

export const setModalAtualizarDadosCnhDigital = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_ATUALIZAR_DADOS_CNH_DIGITAL, value)
}

export const setModalChangePassword = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CHANGE_PASSWORD, value)
}

export const setModalChangeEmail = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CHANGE_EMAIL, value)
}

export const setModalChangeTel = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CHANGE_TEL, value)
}

export const setModalConfigurarNotificacoes = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CONFIGURAR_NOTIFICACOES, value)
}

export const setModalConfigurarPrivacidade = ({ commit }, value) => {
  commit(TYPES.SET_MODAL_CONFIGURAR_PRIVACIDADE, value)
}
