const moment = require('moment')

export const formataDataParaEnvio = (value) => { // de DD/MM/YYYY para YYYY-MM-DD
  if (!value) {
    return null
  }
  const date = moment(value, 'DD/MM/YYYY', true)

  if (!date.isValid()) {
    return null
  }

  return date.format('YYYY-MM-DD')
}
