const NotFound = () => import('./components/NotFound')

export default [
  {
    name: 'catchall',
    path: '*',
    redirect: { name: '404' },
    meta: {
      requiresAuth: false
    }
  },
  {
    name: '404',
    path: '/404',
    component: NotFound,
    meta: {
      requiresAuth: false,
      hideHeader: true,
      hideFooter: true
    }
  }
]
