export default {

  roleHabilitacao: state => {
    return (
      state.roles.indexOf('HABILITACAO') > -1 &&
      state.user &&
      state.user.habilitacao &&
      !!state.user.habilitacao.numeroRegistro
    )
  },

  roleHabilitacaoSemHabilitacao: state => {
    return state.roles.indexOf('HABILITACAO') > -1
  },

  roleVeiculo: (state, getters, rootState) => {
    return state.roles.indexOf('VEICULO') > -1 || rootState.empresaCorrente
  },

  roleAgendamento: state => {
    return state.roles.indexOf('AGENDAMENTO') > -1
  },
  roleBeta: state => {
    return state.roles.indexOf('BETA') > -1
  },
  roleAdmin: state => {
    return state.roles.indexOf('ADMIN') > -1
  },
  urlLogout: (state, getters, rootState) => {
    return `${process.env.VUE_APP_URL_WSO2}/oidc/logout?id_token_hint=${rootState.Auth.idToken}&post_logout_redirect_uri=${process.env.VUE_APP_WSO2_REDIRECT_LOGOUT_URL}`
  },
  logouComGovbr: (state, getters, rootState) => {
    return rootState.Auth.idToken
  },
  loggedUsername: (state, getters, rootState) => {
    const { habilitacao, nome } = rootState.Auth.user
    if (!habilitacao) return nome
    return habilitacao.nomeSocial ? habilitacao.nomeSocial : habilitacao.nomeCnh
  },
  featureToggle: state => chave => {
    if (state.user == undefined) return false
    return state.user.ft && state.user.ft.indexOf(chave) >= 0
  }
}
