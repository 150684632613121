import * as TYPES from './mutations-types'
import { statusSistema } from '@/domains/system/services/SistemaService'

export const applyValores = ({ commit }, data) => {
  commit(TYPES.SET_LISTA_VALORES, data)
}

export const getStatusSistema = ({ commit }) => {
  return statusSistema()
    .then((response) => {
      commit(TYPES.STATUS_SISTEMA, response.data)
      return response.data
    }).catch(er => {
      commit(TYPES.STATUS_SISTEMA, {})
      return {}
    })
}
