import * as TYPES from './mutations-types'
// import * as _ from 'lodash'

export default {
  [TYPES.SET_LISTA_VEICULOS] (state, lista) {
    state.listaVeiculos = lista
  },
  [TYPES.ATUALIZAR_DOSSIE_VEICULO] (state, veiculo) {
    if (state.listaVeiculos && state.listaVeiculos.content && state.listaVeiculos.content.length > 0) {
      state.listaVeiculos.content.forEach((item, i) => {
        if (item.placa === veiculo.placa) {
          Object.assign(state.listaVeiculos.content[i], veiculo)
        }
      })
    }
  }
}
