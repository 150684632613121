import { http } from '@/support/http'
import store from '@/store/index'

export const pontuacao = () => {
  return http.get(`infracoes/pontuacao`)
}

export const buscarAIT = (protocolo, senha) => {
  return http.get(`/infracoes/ait/${protocolo}/${senha}/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`)
}

export const buscarComprovante = (codigoValidacao, captcha) => {
  return http.get(`/infracoes/validar-comprovante?codigo=${codigoValidacao}&captcha=${captcha}`)
}

export const buscarComprovanteSuspensao = (codigoValidacao, captcha) => {
  return http.get(`/infracoes/validar-comprovante-suspensao?codigo=${codigoValidacao}&captcha=${captcha}`)
}

export const processo = data => {
  return http.post(`infracoes/processo/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, data)
}
export const defesa = data => {
  return http.post(`infracoes/defesa/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, data)
}

export const adicionarDocumento = data => {
  return http.post(`infracoes/adicionar-documento/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, data)
}

export const gerarFormulario = (data, enviarPorEmail = false) => {
  return http.post(`infracoes/gerar-formulario/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}?enviarPorEmail=${enviarPorEmail}`, data, {
    responseType: 'arraybuffer'
  })
}

export const avisoRecebimento = data => {
  return http.post(`infracoes/aviso-recebimento/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, data, {
    responseType: 'arraybuffer'
  })
}

export const buscaNotificacaoAutuacao = (protocolo, senha) => {
  return http.get(`/infracoes/na/${protocolo}/${senha}/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, {
    responseType: 'arraybuffer'
  })
}

export const buscaNotificacaoPenalidade = (protocolo, senha) => {
  return http.get(`/infracoes/np/${protocolo}/${senha}/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, {
    responseType: 'arraybuffer'
  })
}

export const baixarPdfConsolidado = (endpoint) => {
  return http
    .post(`/infracoes/pdf-consolidado/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}`, endpoint, {
      responseType: 'arraybuffer'
    })
}

export const baixarComprovanteDefesa = (dados, enviarPorEmail) => {
  return http
    .post(`infracoes/comprovante/${store.state.empresaCorrente ? store.state.empresaCorrente.id : ''}?enviarPorEmail=${enviarPorEmail}`, dados, {
      responseType: 'arraybuffer'
    })
}
