import * as TYPES from './types'
import { listaConfiguracoesEmpresa } from '../domains/requests/services/AdministradorEmpresaService'
import * as services from '@/store/services/PesquisaSatisfacaoService'

export default {
  setFetching ({ commit }, obj) {
    commit(TYPES.MAIN_SET_FETCHING, obj)
  },

  setModalValidarCertidao ({ commit }, mostrar) {
    commit(TYPES.MODAL_VALIDACAO_CERTIDAO, mostrar)
  },

  setModalDefesaAutuacao ({ commit }, mostrar) {
    commit(TYPES.MODAL_DEFESA_AUTUACAO, mostrar)
  },

  setModalNovaEmpresa ({ commit }, mostrar) {
    commit(TYPES.MODAL_NOVA_EMPRESA, mostrar)
  },

  setModalNovaEmpresaSolicitada ({ commit }, mostrar) {
    commit(TYPES.MODAL_NOVA_EMPRESA_SOLICITADA, mostrar)
  },

  setModalAtivo ({ commit }, modal) {
    commit(TYPES.SET_MODAL_ATIVO, modal)
  },

  showModalNovoAdministradorEmpresa ({ commit }) {
    return new Promise((resolve, reject) => {
      commit(TYPES.MODAL_NOVO_ADMINISTRADOR_EMPRESA, { show: true, resolve, reject })
    })
  },

  hideModalNovoAdministradorEmpresa ({ commit }) {
    commit(TYPES.MODAL_NOVO_ADMINISTRADOR_EMPRESA)
  },

  showModalNovoAdministradorEmpresaSuccess ({ commit }, { pendente, nome, email }) {
    commit(TYPES.MODAL_NOVO_ADMINISTRADOR_EMPRESA_SUCCESS, { show: true, pendente, nome, email })
  },

  hideModalNovoAdministradorEmpresaSuccess ({ commit }) {
    commit(TYPES.MODAL_NOVO_ADMINISTRADOR_EMPRESA_SUCCESS, {})
  },

  setAccessibility ({ commit }) {
    commit(TYPES.MAIN_SET_ACCESSIBILITY)
  },

  setFontsize ({ commit }) {
    commit(TYPES.MAIN_SET_FONTSIZE)
  },

  toggleMenu ({ commit }, open) {
    commit(TYPES.MAIN_TOGGLE_MENU, open)
  },

  toggleMenuDeslogado ({ commit }, open) {
    commit(TYPES.MAIN_TOGGLE_MENU_DESLOGADO, open)
  },

  toggleOverlay ({ commit }, show) {
    commit(TYPES.MAIN_TOGGLE_OVERLAY, show)
  },

  atualizaCofiguracaoNotificacoesEmpresa ({ commit }, value) {
    commit(TYPES.SET_CONFIGURACAO_NOTIFICACOES_EMPRESA, value)
  },

  async setEmpresaCorrente ({ commit }, value) {
    commit(TYPES.SET_EMPRESA_CORRENTE, value)
    if (value) {
      commit(TYPES.IS_COMPANY, true)
    } else {
      commit(TYPES.IS_COMPANY, false)
    }

    if (value) {
      const { data } = await listaConfiguracoesEmpresa()
      commit(TYPES.SET_CONFIGURACAO_NOTIFICACOES_EMPRESA, data)
    }
  },

  confirm ({ commit }, options) {
    return new Promise((resolve, reject) => {
      const state = Object.assign({ show: true, resolve, reject }, options)
      commit(TYPES.MODAL_CONFIRM, state)
    })
  },

  confirmOk ({ commit }) {
    commit(TYPES.MODAL_CONFIRM_OK)
  },

  confirmCancel ({ commit }, value) {
    commit(TYPES.MODAL_CONFIRM_CANCEL, value)
  },

  setPrompt ({ commit }, valor) {
    commit(TYPES.DEFERRED_PROMPT, valor)
  },

  showPrompt ({ commit }) {
    commit(TYPES.DEFERRED_PROMPT_SHOW)
  },

  setPwaInstalled ({ commit }, valor) {
    commit(TYPES.PWA_INSTALLED, valor)
  },

  setAndroid ({ commit }, value) {
    commit(TYPES.ANDROID_INSTALLED, value)
  },

  setModalCSAT ({ commit }, mostrar) {
    commit(TYPES.MODAL_CSAT, mostrar)
  },

  registrarResposta ({ commit }, pesquisaSatisfacao) {
    return services.registrarResposta(pesquisaSatisfacao)
      .then(({ data }) => {
        commit(TYPES.ATUALIZAR_PESQUISA_SATISFACAO, data)
        return data
      })
  },

  acceptCookies ({ commit }, value) {
    commit(TYPES.AVISO_COOKIES, value)
  },

  setMostrarAvisoManutencao ({ commit }, value) {
    commit(TYPES.MOSTRAR_AVISO_MANUTENCAO, value)
  }

}

