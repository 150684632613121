import { errorStatusIn } from '@/util/general-util'
import jwtDecode from 'jwt-decode'

// Rotas não interceptadas
const exceptions = [
  new RegExp('auth/(login|recuperar-senha|reenviar-email)'), // Login, esqueci/redefinir/recuperar senha
  new RegExp(
    'usuario/(validar-email|confirmar-alteracao-email|confirmar-alteracao-email-empresarial)'
  ), // Solicitar e finalizar cadastro
  new RegExp('certidao/(buscar?|baixar?)'), // Buscar e baixar certidão por CPF e código de validação
  new RegExp('sistema/status'), // Status do sistema
  new RegExp('crv-despachante'), // Validação CRV Despachante
  new RegExp('jari/validar-documento-parecer-jari'), // Validação Processo Jari
  new RegExp('jari/baixar-documento-parecer-jari'), // Download Validação Processo Jari
  new RegExp('infracoes/validar-comprovante'), // Validação Comprovantes Infração
  new RegExp('govbr/(login)') // Login GovBr
]

const tokenIsValid = token => {
  const { exp } = jwtDecode(token)
  return (new Date()).getTime() / 1000 <= exp
}

export default (http, store, router) => {
  // request interceptors
  http.interceptors.request.use(config => {
    return new Promise(resolve => {
      for (const routeRegex of exceptions) {
        if (routeRegex.test(config.url)) {
          resolve(config)
          return
        }
      }

      const idEmpresaCorrente = store.state.empresaCorrente && store.state.empresaCorrente.id
      if (idEmpresaCorrente) {
        config.headers['X-Empresa'] = idEmpresaCorrente
      }

      store.dispatch('checkUserToken').then(({ token }) => {
        if (tokenIsValid(token)) {
          config.headers.Authorization = `Bearer ${token}`
          resolve(config)
        } else {
          router.push({ name: 'login' })
        }
      })
    })
  })

  // response interceptors
  http.interceptors.response.use(
    response => response,
    error => {
      if (errorStatusIn(error, [401])) {
        router.push({ name: 'logout' })
      }
      return Promise.reject(error)
    }
  )
}
