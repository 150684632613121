import { http } from '@/support/http'

// ATENÇÃO: Ao adicionar uma nova requisição que não precisa de autenticação, lembrar
//          de adicionar a mesma na lista de rotas não interceptadas no interceptors.js!

export const postLogin = (dados) => {
  return http.post('auth/login', dados)
}

export const cadastrarEmpresa = (dados) => {
  const config = {
    header: {
      'Content-Type': 'multipart/form-data'
    }
  }
  return http.post('empresa/solicitar-cadastro', dados, config)
}

export const listarCombinacoesPid = () => {
  return http.get('habilitacao/combinacoes-pid')
}

export const validarEmail = (dados) => {
  return http.post('usuario/validar-email/' + dados)
}

export const validarEmailProcesso = (dados) => {
  return http.post('usuario/validar-email-processo/' + dados)
}

export const reenviarEmail = (dados) => {
  return http.post('auth/reenviar-email', dados)
}

export const recuperarSenha = ({ token, novaSenha }) => {
  return http.post('auth/recuperar-senha', { token, novaSenha })
}

export const trocarSenha = ({ senha, novaSenha }) => {
  return http.post('auth/trocar-senha', { senha, novaSenha })
}

export const getMe = () => {
  return http.get('usuario/me')
}

export const salvarPush = (payload) => {
  return http.post('usuario/push', payload)
}

export const cancelarPush = () => {
  return http.post('usuario/cancelar-push')
}

