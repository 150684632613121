import { format, parse, parseISO as parseDate } from 'date-fns'
import { formatarDocumento, formatarBytes } from '@/util/general-util'
import Vue from 'vue'
import * as _ from 'lodash'

const convertDateValue = (value, vFormat) => {
  let parsedDate
  if (value instanceof Date) parsedDate = value
  else if (value.length === 10) parsedDate = parse(value, 'yyyy-MM-dd', new Date())
  else parsedDate = parseDate(value)

  return format(parsedDate, vFormat)
}

Vue.filter('date', value => {
  if (!value) {
    return null
  }
  return convertDateValue(value, 'dd/MM/yyyy')
})
export const formatDateTime = (value) => {
  if (!value) {
    return null
  }
  return convertDateValue(value, 'dd/MM/yyyy HH:mm')
}
Vue.filter('dateTime', formatDateTime)
Vue.filter('time', value => {
  if (!value) {
    return null
  }
  return convertDateValue(value, 'HH:mm')
})
Vue.filter('time_hm', value => {
  if (!value) {
    return null
  }
  return convertDateValue(value, "HH'h'mm'm'")
})
Vue.filter('year', value => {
  if (!value) {
    return null
  }
  return convertDateValue(value, 'yyyy')
})
Vue.filter('money', value => {
  const val = (value / 1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})
Vue.filter('dinheiro', value => {
  if (value == undefined || value == null) {
    return null
  }
  const val = (value / 1).toFixed(2).replace('.', ',')
  return 'R$ '+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

Vue.filter('cpf', value => formatarDocumento(value))
Vue.filter('cpfCnpj', value => formatarDocumento(value))
Vue.filter('lowercase', value => value ? value.toLowerCase() : value)
Vue.filter('capitalizarNome', value => {
  if (!value) return ''
  var capitalized = []
  var nomeArray = value.split(' ')
  var articleAndPrepositions = ['el', 'la', 'le', 'les', 'los', 'os', 'a', 'as', 'e', 'da', 'de', 'di', 'do', 'du', 'das', 'dos', 'deu', 'der', 'van', 'von', 'bin', 'della']

  nomeArray.forEach(function (nome) {
    nome = nome.toLowerCase()
    if (articleAndPrepositions.indexOf(nome) === -1) {
      nome = _.startCase(_.toLower(nome))
    }
    capitalized.push(nome)
  })

  return capitalized.join(' ')
})

Vue.filter('bytes', value => formatarBytes(value))
