import { isAfter, addMinutes, formatISO, parseISO } from 'date-fns'

export const errorStatusIn = (error, array) => {
  const { response } = error
  return array.indexOf(((response || {}).status) || {}) > -1
}

export const tratarErro = (erro) => {
  console.log(erro)
  if (erro === null || erro === undefined) {
    return null
  } else if (typeof erro === 'string') {
    return erro
  } else if (erro.message === 'Network Error') {
    return 'Erro de comunicação, verifique sua conexão'
  }  else if (
    erro.response &&
    erro.response.data &&
    ((erro.response.data.length > 0 && erro.response.data[0].mensagemUsuario) //Se for array
    || erro.response.data.mensagemUsuario)) //Se não for array (apenas objeto)
  {
    return erro.response.data.mensagemUsuario || erro.response.data[0].mensagemUsuario 
  } else if (erro.response && erro.response.data && erro.response.data.message) {
    return erro.response.data.message
  } else if (erro.response && erro.response.data && erro.response.data.mensagemUsuario) {
    return erro.response.data.mensagemUsuario
  } else if (erro.mensagemUsuario) {
    return erro.mensagemUsuario
  } else if (erro.name === 'Error') {
    return erro.message
  } else {
    return 'Não foi possível processar sua requisição'
  }
}

export const formatarDocumento = (documento) => {
  if (documento === undefined || documento === null) {
    return ''
  }

  if (documento.length === 11) { // CPF
    return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
  } else if (documento.length === 14) { // CNPJ
    return documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
  } else { // Outros
    return documento
  }
}

export const removerFormatacaoDocumento = (documento) => {
  if (documento === undefined || documento === null) {
    return ''
  }

  const pattern = new RegExp('^[0-9\./-]+$', 'g')
  if (pattern.test(documento)) {
    return documento.split('.').join('').split('-').join('').split('/').join('')
  }
  return documento
}

export const naoSimOpcoesRadio = [{ label: 'Não', value: false }, { label: 'Sim', value: true }]

/** DEPRECATED - Usar o código em request-util */
export const requestUntilConditionMet = (request, checkCondition, retries, time) =>
  request()
    .then(retorno => {
      if (checkCondition(retorno)) {
        return Promise.resolve(retorno)
      } else {
        return wait(time).then(() => retries > 1 ? requestUntilConditionMet(request, checkCondition, retries - 1, time) : Promise.reject({ mensagemUsuario: 'Não foi possível processar sua requisição' }))
      }
    })
    .catch(err => {
      return Promise.reject(err)
    })

/** DEPRECATED - Usar o código em request-util */
export const wait = (ms) => new Promise(r => setTimeout(r, ms))

export const formatarBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return (parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]).replace('.', ',')
}

export const readFileAsText = file => {
  const fileReader = new FileReader()
  return new Promise((resolve, reject) => {
    fileReader.onerror = e => {
      fileReader.abort()
      reject(new Error('Erro ao processar resposta'))
    }
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    const blob = new Blob([file])
    fileReader.readAsText(blob)
  })
}

export const checkCanUpdate = (now) => {
  return new Promise((resolve, reject) => {
    let nextUpdate = localStorage.getItem('nextUpdate')

    if (!nextUpdate) {
      nextUpdate = addMinutes(now, 5)
      localStorage.setItem('nextUpdate', formatISO(nextUpdate))
      resolve()
      return
    }
    if (isAfter(now, parseISO(nextUpdate))) {
      nextUpdate = addMinutes(now, 5)
      localStorage.setItem('nextUpdate', formatISO(nextUpdate))
      resolve()
      return
    }

    reject()
  })
}
