import { http } from '@/support/http'
import store from '@/store/index'

function isModoEmpresa () {
  return store.state.empresaCorrente
}

export const carregaVeiculos = page => {
  const pageRequest = {
    filter: {
      idUsuario: null,
      texto: null
    },
    pageInfo: {
      size: 10,
      number: 0
    }
  }
  Object.assign(pageRequest, page)
  if (isModoEmpresa()) {
    pageRequest.filter.idUsuario = store.state.empresaCorrente.id
  } else {
    pageRequest.filter.idUsuario = null
  }
  return http.post('veiculo', pageRequest)
}

export const solicitarLicenciamento = veiculo => {
  if (isModoEmpresa()) {
    return http.post(`veiculo/${store.state.empresaCorrente.id}/crlve`, veiculo)
  }
  return http.post(`veiculo/crlve`, veiculo)
}
