var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.url)?_c('button',{ref:"elem",class:['btn', {
    [`btn-${_vm.color}`]: _vm.color,
    'btn-icon': _vm.icon,
    'btn-flat': _vm.flat,
    'btn-filled': _vm.filled,
    'btn-outlined': _vm.outlined,
    'btn-submit': _vm.submit,
    'theme-dark': _vm.dark,
    'btn-active': _vm.active,
    'btn-small': _vm.small,
    'btn-tiny': _vm.tiny,
    'btn-block': _vm.block
  }],attrs:{"type":_vm.checkTypeButton(),"disabled":_vm.disabled || _vm.manutencao},on:{"click":_vm.triggerClickEvent}},[(!_vm.rightIcon)?_vm._t("icon"):_vm._e(),(_vm.text)?_c('span',{staticClass:"btn-text",domProps:{"innerHTML":_vm._s(`${_vm.text} ${_vm.manutencao ? '<small>(Indisponível)</small>' : ''}`)}}):_vm._e(),(_vm.rightIcon)?_vm._t("icon"):_vm._e()],2):(_vm.urlInternal)?_c('router-link',{class:['btn', `btn-${_vm.color}`, {
    'btn-flat': _vm.flat,
    'btn-filled': _vm.filled,
    'btn-outlined': _vm.outlined,
    'theme-dark': _vm.dark
  }],attrs:{"to":_vm.getUrl()}},[(!_vm.rightIcon)?_vm._t("icon"):_vm._e(),_c('span',{staticClass:"btn-text",domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.rightIcon)?_vm._t("icon"):_vm._e()],2):(!_vm.urlInternal)?_c('a',{class:['btn', {
    [`btn-${_vm.color}`]: _vm.color,
    'btn-icon': _vm.icon,
    'btn-flat': _vm.flat,
    'btn-filled': _vm.filled,
    'btn-outlined': _vm.outlined,
    'btn-submit': _vm.submit,
    'theme-dark': _vm.dark
  }],attrs:{"href":_vm.url,"target":"_blank"}},[(_vm.text)?_c('span',{staticClass:"btn-text",domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),_vm._t("icon")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }